import { Affix, Divider, Layout, Typography, Spin, Card, Button, Row, Col, Breadcrumb, Space, Anchor, Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Contents from "./Contents";
import Html from "../utils/Html";
import conditional from "../utils/conditional";
import moment from "moment";
import { trimLastSlash, ucfirst } from "../utils/Utils";
import Home from "./Home";
export default function Page(props) {

    const location = useLocation();

    const location_pathname = trimLastSlash(location.pathname);
    const ref = useRef();
    const { results = [], section = "", canEdit = false, drawBackButton = () => { }, menuVisible = false, setMenuVisible = () => { } } = props;
    const current = results.find(item => "/" + section + "/" + item.path === location_pathname) || { ID: 0, heading: "", slug: section, type: section, details: "", date: "", path: "/" + section, parent: 0 }
    const [height, setHeight] = useState(100);

    const peers = results.filter(item => item.parent === current.parent && item.type === section);
    const children = results.filter(item => item.parent === current.ID && item.type === section);
    const level = (location_pathname.split("/").length);
    const anchors = (level > 4 && children.length > 0) ? children : [];

    const [initLoad, setInitLoad] = useState(true);

    const navigate = useNavigate();

    const drawAnchors = () => {

        if (level < 4) {
            return (<></>)
        }

        if (children.length < 1) {
            return (<></>)
        }

        return (
            <Anchor showInkInFixed={true} offsetTop={80}>
                <conditional.true value={current.details}>
                    <Anchor.Link key={"top"} href={"#" + current.slug} title={current.heading} />
                </conditional.true>
                {children.map((item, index) => {
                    return (
                        <Anchor.Link key={index} href={"#" + item.slug} title={item.heading} />
                    );
                })}
            </Anchor>
        )
    }

    const shiftTo = (_hash) => {
        let element = document.getElementById(_hash);
        if (!element) {
            return;
        }
        var headerOffset = 80;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    const gotoAnchor = () => {

        if (location.hash && initLoad) {
            setTimeout(() => {
                shiftTo(location.hash.substring(1, location.hash.length));
            }, 300)
        }
        setInitLoad(false);
    }

    useEffect(gotoAnchor, [initLoad]);




    const linkTo = (_obj) => {
        // let _path = _obj.path.replace(/.*\/\/[^\/]*/, ''); 
        // if(_path.substr(_path.length-1, 1) !== "/"){
        //     _path = _path + "/";
        // }
        let _path = "/" + section + "/" + _obj.path;
        navigate(_path);
    }



    const getAdminLink = (_link = false) => {

        let _path = "/wp-admin/post.php?post=" + current.ID + "&action=edit";
        
        if (canEdit) {

            if(_link){
                return(<a href={_path}><small>Edit Page</small></a>)
            }

            return (<div style={{ "float": "right" }}><Button onClick={() => window.open(_path)} type="primary" size="small"><small>Edit Page</small></Button></div>)
        }
    }



    const getHeight = () => {

        setTimeout(() => {

            if (window.innerWidth < 600) {
                return;
            }

            let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            let h = ref.current.clientHeight || 0;




            if (h > vh) {
                setHeight(-(h - vh))
            } else {
                setHeight(105)
            }

        }, 500)


    }
    useEffect(getHeight, [location.pathname])



    const drawPath = (_p) => {

        _p = _p.replace("#", "/");

        if (_p.includes("/filter")) {
            // cull filters from breadcrumbs

            _p = _p.split("/filter")[0];
        }

        let items = _p.split("/");

        if (!_p.length > 0) {
            return (<></>);
        }
        let m = [];
        return items.map((itm, ind) => {
            m.push(itm);
            let x = [...m];
            if (itm === "") {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate("/")}>Home</a></Breadcrumb.Item>
            } else {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate(x.join("/"))}>{ucfirst(itm)}</a></Breadcrumb.Item>
            }
        })
    }

    const drawItem = (_item) => {

        return (
            <div id={_item.slug}>
                <Typography.Title level={4}>
                    <Row>
                        <Col flex="auto">{_item.heading}</Col>
                    </Row>
                </Typography.Title>
                <div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(_item.date).format("MMM Do, YYYY")}</em></div>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <Html html={_item.details} />
                <br /><br />
            </div>
        )
    }

    const drawContent = () => {

        return (
            <div style={{ "maxWidth": "1200px", "display": "flex" }}>


                <div style={{ "flex": "1" }}>

                    {(current.details &&
                        <div>
                            {getAdminLink()}
                            {drawItem(current)}
                        </div>
                    )}

                    {((level > 3 && children.length > 0) &&
                        children.map(item => {
                            return drawItem(item);
                        })
                    )}



                </div>
                <div className="show" style={{ "width": "250px" }}>
                    <div style={{ "paddingLeft": "20px" }}>
                        {drawAnchors()}
                    </div>
                </div>


            </div>
        )
    }

    const drawMenu = () => {
        return (
            <div>
                {getAdminLink()}

                <Typography.Title level={4}>{current.heading}</Typography.Title>
                <br />
                <conditional.true value={(current.heading && current.date)}><div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(current.date).format("MMM Do, YYYY")}</em></div></conditional.true>
                <conditional.true value={(current.heading)}><Divider dashed style={{ "margin": "15px 0px" }} /></conditional.true>
                <div className="shim" /><div className="shim" />

                <div style={{ "display": "grid", "gap": "20px", "gridTemplateColumns": "repeat(auto-fit, minmax(250px, 1fr))" }}>
                    {children.map(_item => {
                        return (<Card hoverable style={{ "maxWidth": "358px" }} key={_item.path} onClick={() => navigate("/" + section + "/" + _item.path)} >
                            <Card.Meta title={_item.heading} description={getAdminLink(true)} />
                        </Card>)
                    })}
                </div>
            </div>
        )
    }


    const AffixWrapper = (props) => {

        return (
            <div>
                <Affix className="show" offsetTop={height - 34}>
                    <div ref={ref} className="page" style={{ "minHeight": "calc(100vh - 70px)" }}>
                        {props.children}
                    </div>
                </Affix>
                <div className="hide">
                    <div className="page" style={{ "minHeight": "calc(100vh - 70px)" }}>
                        {props.children}
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>

            {/* <LayoutHeader
                title="Welcome to the Bookmanager Help Centre!"
                description="Use this area to upload images to be displayed on your webstore's site."
            /> */}

            <Layout style={{ "padding": "0px" }} className="layout">

                
                <Drawer
                    title={<div style={{ "height": "15px" }}></div>}
                    placement="left"
                    width={300}
                    closable={true}
                    onClose={() => setMenuVisible(false)}
                    bodyStyle={{ "padding": "0px", "overflowX": "hidden", "overflowY": "auto" }}
                    visible={menuVisible}
                >
                    <Contents mobile shiftTo={shiftTo} anchors={anchors} peers={peers} current={current} data={results} section={section} />
                </Drawer>
                <Contents shiftTo={shiftTo} anchors={anchors} peers={peers} current={current} data={results} section={section} />
                <Layout.Content>

                    <AffixWrapper>

                        <div className="pageOffset" style={{ "marginLeft": "-15px" }}>
                            <div style={{ "float": "right" }}>{drawBackButton()}</div>
                            <Breadcrumb style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}>
                                {drawPath(location.pathname)}
                            </Breadcrumb>
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                        </div>
                        <conditional.true value={current.ID === 0}>
                            <Home data={results.find(item => item.path === "home")} />
                        </conditional.true>
                        <conditional.true value={(current.details || level > 4)}>
                            {drawContent()}
                        </conditional.true>

                        <conditional.true value={(!current.details && level < 5)}>
                            {drawMenu()}
                        </conditional.true>

                    </AffixWrapper>


                </Layout.Content>
            </Layout>

        </>
    )
}