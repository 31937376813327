import { Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Contents(props) {
    const { data = [], section = "manual", current = {}, peers = [], anchors = [], shiftTo = () => {}, mobile = false } = props;
    const location = useLocation(); 
    const navigate = useNavigate(); 

    const route = (location.hash) ? current.path + location.hash.replace("#", "/") : current.path;
    const linkTo = (_obj) =>{
      
        let _find = anchors.find(item => item.slug === _obj.slug);
        if(_find){
            navigate("#" + _find.slug);
            shiftTo(_find.slug);
            return; 
        }

        let _path = "/" + section + "/" + _obj.path; 
        navigate(_path); 
    }


    const getSubs = (_item) => {
        let sp = _item.replace("#","/");
        sp = _item.split("/");
        let subs = [];
        for(let i = 0; i<sp.length; i++){
            let a = []
            for(let n = 0; n<=i; n++){
                a.push(sp[n])
            }
            subs.push(a.join("/"))
        }
        return subs; 
    }

   


    const drawItem = (_obj) => {
        return (<Menu.Item onClick={() => linkTo(_obj)} key={_obj.path}>{_obj.heading}</Menu.Item>)
    }

    const drawItems = (_obj) => {
        let children = data.filter(item => item.parent === _obj.ID);
        if (children.length > 0) {
            return (<Menu.SubMenu key={_obj.path} onTitleClick={() => linkTo(_obj)} title={<div onClick={() => linkTo(_obj)}>{_obj.heading}</div>}>{children.map(itm => { return drawItems(itm) })}</Menu.SubMenu>)
        } else {
            return drawItem(_obj)
        }
    }

    const processData = (_peers = false) => {

        if(_peers){
            return _peers.map(item => {
                return drawItems(item);
            })
        }

      
        return data.filter(item => item.parent === rootPage && item.type === section).map(item => {
            return drawItems(item);
        })
    }

    const getRootPage = (url) => {
        const path = url.replace(/^https?:\/\/[^\/]+/, '');
        const parts = path.split('/');
        
        if (parts.length > 2) {
            let _find = data.find(item => item.path === parts[2] && item.type === section)
            if(_find){
                return _find.ID;
            } else {
                return 0;
            }
        } else {
          return 0;
        }
    }

    const rootPage = (section === "tutorials") ? getRootPage(location.pathname) : 0;

    if(rootPage === 0 && section === "tutorials"){
        return(<></>);
    }

    return (
        <Layout.Sider className={(mobile) ? "typeNavMobile" : "typeNav"} theme="light" width={300}>
            <Menu style={{"height" : "100%", "paddingTop" : "10px"}} openKeys={getSubs(current.path)} selectedKeys={[route]} mode="inline" >
                {processData()}
            </Menu>
        </Layout.Sider>
    )
}